/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, lazy } from "react";
import PropTypes from "prop-types";
import { hotjar } from 'react-hotjar';
import { useStaticQuery, graphql, Script, navigate } from "gatsby";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import loadable from '@loadable/component';
import Header from "./Header/Header";
import TypeformProvider
  from "./TypeformContext/TypeformContext";

const ExitIntentModal = loadable(() => import("./ExitIntentModal/ExitIntentModal"))
import Footer from "./Footer/Footer";

const TypeformModal = loadable(() => import("./TypeformModal/TypeformModal"))

const Layout = ({
  children,
  hideHeader = false,
  hideFooter = false,
  footerCta,
  disableGTM = false,
  location,
}) => {
  const {
    wp: { seo },
    menuFooter
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      menuFooter: wpMenu(slug: { eq: "footer-2023" }) {
        id
        slug
        menuItems {
          nodes {
            label
            parentDatabaseId
            parentId
            path
            id
            childItems {
              nodes {
                label
                url
                path
                id
              }
            }
          }
        }
        footer {
          logosFirstLine {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          logosSecondLine {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            responsiveImage: image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, height: 80)
                }
              }
            }
          }
        }
      }
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);


  const ref = useRef(null);
  const headerRef = useRef(null);

  // Get height from header component
  const [headerHeight, setHeaderHeight] = useState(93);

  useEffect(() => {
    if(headerRef?.current?.offsetHeight >= 90) {
      setHeaderHeight(headerRef?.current?.offsetHeight);
    }
  }, [headerHeight]);

  // Update headerHeight when window is resized
  useEffect(() => {
    const handleResize = () => {
      if(headerRef?.current?.offsetHeight) {
        setHeaderHeight(headerRef?.current?.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [headerHeight]);

  // Only on client side
  useEffect(() => {
    if(typeof window !== 'undefined') {
      // data layer notification for hydration
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        hydrated: 'hydrated',
        event: 'hydrated'
      })

      // Hotjar init
      if (location?.pathname === '/' || location?.pathname === '/digital-marketing-services/') {
        hotjar.initialize('223466', '6');
      }
    }
  }, [])

  // Set environment variable
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <>
      {isProduction && (
        <>
          <Script>
            {`
              (function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){(w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')
            `}
          </Script>
          <Script src='https://js.qualified.com/qualified.js?token=F6PdNg9rAEuFAiRB' />
        </>
      )}
      <SEOContext.Provider value={{ global: seo }}>
        <TypeformProvider>
          <div
            className={`font-body flex min-h-screen flex-col overflow-x-hidden font-all text-[#000] antialiased ${
              process.env.NODE_ENV === `development` ? "debug-screens" : ""
            }`}
            // set margin top to header height
            // style={{ marginTop: hideHeader ? 0 : headerHeight || 93 }}
          >
            <ExitIntentModal />
            {!hideHeader && <Header headerRef={headerRef} />}
            <main className="w-full" style={{marginTop: `${headerHeight}px`}}>{children}</main>
            {!hideFooter && (
              <Footer menuFooter={menuFooter} footerCta={footerCta} />
            )}
          </div>
          <TypeformModal />
        </TypeformProvider>
      </SEOContext.Provider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  footerCta: PropTypes.object,
  location: PropTypes.object,
  disableGTM: PropTypes.bool,
};

export default Layout;
